<template>
  <a-form
    ref="formRef"
    :model="formState"
    name="basic"
    :validate-trigger="['change', 'blur']"
    label-wrap
    label-align="left"
  >
    <a-form-item
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      :label="t('alert_coordinator.send_to')"
      :name="['username']"
    >
      <cds-select
        v-model:value="formState.sendTo"
        :show-count="false"
        :maxlength="100"
        :placeholder="t('alert_coordinator.send_to_placeholder')"
      />
    </a-form-item>
    <a-form-item
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      :label="t('alert_coordinator.group')"
      :name="['username']"
    >
      <cds-select
        v-model:value="formState.description"
        :show-count="false"
        :maxlength="100"
        :placeholder="t('alert_coordinator.group_placeholder')"
      />
    </a-form-item>
    <div class="h-1px w-full mx-auto bg-ems-gray700 mt-17px mb-14px"></div>
    <div class="text-ems-gray200 text-16px font-semibold mb-15px">
      {{ t('alert_coordinator.recently_coordinator') }}
    </div>
    <div class="flex flex-col gap-y-16px h-380px overflow-y-auto w-[calc(100%+15px)]">
      <template v-for="item in listRecentCoordinator" :key="item.id">
        <div
            class="w-[calc(100%-10px)] bg-ems-tag1 p-20px flex gap-x-30px items-center rounded-8px"
        >
          <a-checkbox
              v-model:checked="formState.camera"
              class="text-ems-gray300 checkbox-custom"
          >
          </a-checkbox>
          <div class="flex items-center gap-x-25px">
            <img
                src="../../../static/img/exampleImage/ExampleImg2.png"
                alt="ExampleImg2"
                class="w-89px h-89px object-cover rounded-[50%]"
            />
            <div class="flex flex-col">
              <div class="mb-7px text-16px font-semibold text-ems-gray100">
                {{ item.name }}
              </div>
              <div class="flex">
                <div
                    class="px-10px py-3px text-12px leading-19px rounded-4px text-gray-100"
                    :class="item.status === 1 ? 'bg-ems-boTro4_600' : 'bg-ems-gray600'"
                >
                  {{ item.statusName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-center mt-27px">
      <ClipButton class="flex items-center" :type="'primary'">
        <span>{{ t('common.send') }}</span>
      </ClipButton>
    </div>
  </a-form>
</template>
<script setup>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';

const { t } = useI18n();
let formState = reactive({
  sendTo: null,
  group: null,
  recentCoordinator: false,
});
const listRecentCoordinator = [
  { id: 1, name: 'Annette Black', status: 1, statusName: 'Đang online' },
  { id: 2, name: 'Annette Black', status: 0, statusName: 'Offline' },
  { id: 3, name: 'Annette Black', status: 0, statusName: 'Offline' },
  { id: 4, name: 'Annette Black', status: 1, statusName: 'Đang online' },
  { id: 5, name: 'Annette Black', status: 1, statusName: 'Đang online' },
  { id: 6, name: 'Annette Black', status: 1, statusName: 'Đang online' },
  { id: 7, name: 'Annette Black', status: 0, statusName: 'Offline' },
];
</script>
<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
}
</style>
